<template>
  <PageHeader :title="title" />

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true" >
      <button id="info" @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>

    <!-- инфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />
  
  <tablecustom 
    @search="searchB" 
    :dataParams="dataParams"
    :objParams="objParams"
    :columns="columns" 
    :rows="rows" 
    :pages="objPages"
    :showpag="true"
    :tabs="othertabs"
    :addtabs="true"
    @addCategory="showFilterBox = true"
    @removeCategory="removeCategory"
    @getdata="getdata"
    @changelimit="changelimit"
    @changeFilter="changeFilter" 
    @clearFilter="clearFilter" 
    @open="open"
    @changeTab="changeTab"
  />

  <!-- карточка тикета -->
  <viewbox 
      v-if="showModal" 
      @close="showModal = false" 
      :obj="obj" 
    ></viewbox>

    <!-- таби -->
    <filterTabs 
      v-if="showFilterBox" 
      @close="showFilterBox = false"
      @changeTabs="changeTabs"
      :othertabs="othertabs"
    >
    </filterTabs>

</template>

<script>
import PageHeader from "@/components/page-header";
import infoblocks from '@/components/info/blocks'
import filterTabs from './filterTicketsTab'
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/newtickets/view/index.vue'
import { Archive } from '@/API.js';
import { storeS } from "@/store";
import { mutateTypeTicket, 
        mutateWayproblem,
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
      } from '@/usabilityScripts/globalMutate.js'

let apiArchive = new Archive();

export default {
  components: {
    PageHeader,
    tablecustom,
    infoblocks,
    viewbox,
    filterTabs
  },
  data() {
    return {
      title: "archiveTickets",
      showModal: '',
      obj: {},
      objPages: {},
      dataParams: {
          status: true,
          page: "tickets"
      },
      objParams:{
          page: '1',
          pagelimit: '10',
          search: '',
          // status: '',
          priority: '',
          workerId: '',
          type: '',
          inJobWorkerId: '',
          pr1: '',
          pr2: ''
      },
      showFilterBox: false,
      othertabs: [],
      columns: [
        {
          name: this.$t("ID"),
          text: "id",
          align: "right",
          status: true
        },
        {
          name: this.$t("ticket"),
          text: "type",
          align: "left",
          mutate: (item) => this.mutateticket(item),
          status: true
        },
        {
          name: this.$t("ticket_name"),
          text: "ticketName",
          align: "left",
          status: true
        },
        {
          name: this.$t("problem_area"),
          text: "problemEnvironment",
          align: "left",
          mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
          status: true
        },
        {
          name: this.$t("Status"),
          text: 'status',
          align: "left",
          status: true,
          mutate: (item) => this.mutateStatus(item).name,
          mutateClass: (item) => this.mutateStatus(item).color
        },
        {
          name: this.$t("Manager"),
          text: "closeWorkerName",
          align: "right",
          status: true
        },
        {
          name: this.$t("clients"),
          text: "userPhones",
          align: "right",
          mutate: (item) => item ? this.mutateNumber(item) : '-',
          status: false,
        },
      ],
      rows: [],
      infoblockShow: false,
      information: [
        {
            title: this.$t('totalTickets'),
            value: "0",
            icon: "ri-ticket-2-line",
            color: "secondary"
        },
      ]
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiArchive.getAllArchiveTickets(this.objParams).then(result => {
        if(result.status == 'done'){
          this.objPages = result.data;
          this.rows = result.data.items;  

          this.information[0].value = result.data.total
        }
      })
    },
    open(e){
      apiArchive.getArchiveTicket(e.id).then(result => {
        if(result.status == 'done'){
          this.obj = result.data;
          this.showModal = true;
        }
      })
    },
    changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
    },
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    changeFilter(name, value){
      if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else {
          this.objParams[name] = value;
        }
        this.getdata();
      } else {
        this.objParams = {
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          taskName: '',
          priority: '',
          workerId: '',
          pr1: '',
          pr2: ''
        },
        this.getdata()
      }
    },
    mutateNumber(value){
      var r = []
      if(value != ''){
        for(var item in value){
          r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
        }
      }
      return r
    }, 
    changeTab(name, value){
      console.log(name, value)
      var arrFilter = ['search','status', 'priority', 'type', 'problemEnvironment','workerId', 'inJobWorkerId', 'decidedStatus'];
      for(var item in arrFilter){
        if(arrFilter[item] == name){
          this.objParams[name] = value
        } else {
          if(name == 'pr1') {
            this.objParams.search = '',
            this.objParams.priority = '',
            this.objParams.status = '',
            this.objParams.type = '',
            this.objParams.problemEnvironment = '',
            this.objParams.workerId = '',
            this.objParams.inJobWorkerId = '',
            this.objParams.decidedStatus = ''
            let today = String(new Date()).split(' ');
            if(value == 'today'){
              this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
              this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])+1)
              this.getdata()
            } else if(value == 'yesterday'){
              this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])-1)
              this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2]))
              this.getdata()
            }
            return
          }
          this.objParams[arrFilter[item]] = '';
        }
      }
      this.getdata();
    },
    changeTabs(items){
      localStorage.setItem("ArchiveTicketscheckbox", JSON.stringify(this.othertabs));
      localStorage.setItem("resultArchiveTickets", this.othertabs.length)
    },
    removeCategory(e, i){
      this.othertabs.splice(i, 1)
      localStorage.setItem("ArchiveTicketscheckbox", JSON.stringify(this.othertabs));
    },
    mutateticket(e){
      return mutateTypeTicket(e)
    },
    mutatewayproblem(e){
      return mutateWayproblem(e)
    },
    mutateStatus(e){
      return mutateStatusTicket(e)
    },
    mutateDecidedStatus(e){
      return mutateDecidedStatusTicket(e)
    },
    mutatepriority(e){
      return mutatePriorityTicket(e)
    },
    mutateProblemEnv(e){
      return mutateProblemEnv(e)
    },
    nameTag(value){
      var r = []
      if(value != ''){
        for(var item in value){
          r.push('<span class="fs-11 fw-semibold" style="background:' + nameTag(value[item]).color + ';border-radius:5px;padding:5px;color:white;">'+nameTag(value[item]).name+'</span>')
        }
      }
      return r
    }
  },
  mounted(){
    this.othertabs = localStorage.getItem("ArchiveTicketscheckbox") ? JSON.parse(localStorage.getItem("ArchiveTicketscheckbox")) : [];
    // if((this.user.userid == 57 || this.user.userid == 34) && this.othertabs[0] == undefined || this.othertabs[0]?.value != this.user.userid){
    //   this.othertabs.unshift({
    //     checked: true,
    //     color: "#1ABC9C",
    //     obj: "inJobWorkerId",
    //     tab: "inJobWorkerId"+this.user.userid,
    //     title: this.$t("My"),
    //     value: this.user.userid
    //   })
    // }
  },
  computed: {
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    },
    checks() {
      return storeS.checks
    },
  },
};
</script>

<style scoped>
  .tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
  }
  .howto_block {
  margin-left:10px;
  }
  .howto_block i {
  font-size: 23px;
  opacity: 0.5;
  }
  .howto_block i:hover{
  opacity: 1;
  }

  .howto_block {
  position: relative;
  display: inline-block;
  }

  .howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
  }

  .howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
  }

  .howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  }
</style>
